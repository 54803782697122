(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('TraceViewerService', TraceViewerService);

    TraceViewerService.$inject = ["$q", "aerosApi"];

    function TraceViewerService($q, aerosApi) {
        var service = {
            setZoom: setZoom,
            getZoom: getZoom,
            getWavelengthColors: getWavelengthColors,
            getTraceViewerDataEvents: getTraceViewerDataEvents,
            _traceViewerEventsPromises: _traceViewerEventsPromises
        };

        var _zoom;
        var _traceViewerEventsPromises = [];

        function setZoom(zoom, config) {
            if (!_zoom) {
                _zoom = {};
            }

            if (!_zoom[config.fiberGroupId]) {
                _zoom[config.fiberGroupId] = {};
            }

            _zoom[config.fiberGroupId][config.fiberId] = zoom;
        }

        function getZoom(config) {
            if (_zoom && config && config.fiberGroupId && config.fiberId && _zoom[config.fiberGroupId]) {
                return _zoom[config.fiberGroupId][config.fiberId];
            }
        }

        function getWavelengthColors() {

            if (!service.wavelengthColors)
            {
                service.wavelengthColors = {
                    "1310": {
                        color: 'red'
                    },
                    "850": {
                        color: 'red'
                    },
                    "1550": {
                        color: 'blue'
                    },

                    "1300": {
                        color: 'blue'
                    }
                };
            }

            return service.wavelengthColors;
        }

        function getTraceViewerDataEvents(organizationId, projectId, fiberGroupId, fiberId, isAdjusted) {

            if (!_traceViewerEventsPromises[fiberGroupId]) {
                _traceViewerEventsPromises[fiberGroupId] = [];
            }

            if (!_traceViewerEventsPromises[fiberGroupId][fiberId]) {
                _traceViewerEventsPromises[fiberGroupId][fiberId] = [];
            }

            return _traceViewerEventsPromises[fiberGroupId][fiberId][isAdjusted ? 1:0] ||
                (_traceViewerEventsPromises[fiberGroupId][fiberId][isAdjusted ? 1:0] = $q.all({
                traceData: aerosApi.getTraceViewerData({
                    organizationId: organizationId,
                    projectId: projectId,
                    fiberGroupId: fiberGroupId,
                    fiberId: fiberId,
                    isAdjusted: isAdjusted
                }),
                events: aerosApi.getTraceViewerEvents({
                    organizationId: organizationId,
                    projectId: projectId,
                    fiberGroupId: fiberGroupId,
                    fiberId: fiberId
                })
            }));
        }

        return service;
    }
}());
